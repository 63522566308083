import Resources from './Resources'
import Menu from '../components/Menu'
import Section1 from '../components/Section1'
import Section2 from '../components/Section2'
import Page1 from '../components/Page1'
import Page2 from '../components/Page2'
import Page3 from '../components/Page3'
import Page4 from '../components/Page4'
import Page5 from '../components/Page5'

export default {
    "/": {
        name: "Menu",
        exact: true,
        component: Menu,
        background: Resources.MenuBackgroundImage,
        content: Resources.MenuContentImage
    },
    "/section1": {
        name: "Section1",
        exact: true,
        component: Section1,
        background: Resources.Section1BackgroundImage,
        content: Resources.Section1ContentImage,
        transition: {
            in: Resources.Section1TransitionIn,
            out: Resources.Section1TransitionOut,
            switch: Resources.Section1SwitchTransition
        },
        styles: {
            width: '3%',
            top: '59%',
            left: '19%'
        }
    },
    "/section2": {
        name: "Section2",
        exact: true,
        component: Section2,
        background: Resources.Section2BackgroundImage,
        content: Resources.Section2ContentImage,
        transition: {
            in: Resources.Section2TransitionIn,
            out: Resources.Section2TransitionOut,
            switch: Resources.Section2SwitchTransition
        },
        styles: {
            width: '3%',
            top: '61%',
            left: '68%'
        }
    },
    "/section1/page1": {
        name: "Page1",
        component: Page1,
        background: Resources.Page1BackgroundImage,
        content: Resources.Page1ContentImage,
        transition: {
            in: Resources.Page1TransitionIn,
            out: Resources.Page1TransitionOut
        },
        styles: {
            width: '3%',
            top: '38%',
            left: '20%'
        }
    },
    "/section1/page2": {
        name: "Page2",
        component: Page2,
        background: Resources.Page2BackgroundImage,
        content: Resources.Page2ContentImage,
        transition: {
            in: Resources.Page2TransitionIn,
            out: Resources.Page2TransitionOut
        },
        styles: {
            width: '3%',
            top: '45%',
            left: '42%'
        }
    },
    "/section1/page3": {
        name: "Page3",
        component: Page3,
        background: Resources.Page3BackgroundImage,
        content: Resources.Page3ContentImage,
        transition: {
            in: Resources.Page3TransitionIn,
            out: Resources.Page3TransitionOut
        },
        styles: {
            width: '3%',
            top: '34%',
            left: '64%'
        }
    },
    "/section2/page4": {
        name: "Page4",
        component: Page4,
        background: Resources.Page4BackgroundImage,
        content: Resources.Page4ContentImage,
        transition: {
            in: Resources.Page4TransitionIn,
            out: Resources.Page4TransitionOut
        },
        styles: {
            width: '3%',
            top: '47%',
            left: '81%'
        }
    },
    "/section2/page5": {
        name: "Page5",
        component: Page5,
        background: Resources.Page5BackgroundImage,
        content: Resources.Page5ContentImage,
        transition: {
            in: Resources.Page5TransitionIn,
            out: Resources.Page5TransitionOut
        },
        styles: {
            width: '3%',
            top: '50%',
            left: '58%'
        }
    }
}