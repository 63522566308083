import React from 'react'
import { Route } from 'react-router-dom'
import Resources from './constants/Resources'
import Routes from './constants/Routes'

export const { OverlayImage } = Resources
export const RouteObject      = Routes

export const Hotspots = Object.keys(Routes).filter(route => route !== '/').map(route => {
    const { transition, styles } = Routes[route]

    return { to: route, transition, styles  }
})

export const Backgrounds = Object.fromEntries(Object.keys(Routes).map(route => [
    Routes[route].name,
    Routes[route].background
]))

export const Content = Object.fromEntries(Object.keys(Routes).map(route => [
    Routes[route].name,
    Routes[route].content
]))

export const Transitions = Object.fromEntries(
    Object.keys(Routes).map(route => [
        Routes[route].name,
        Routes[route].transition ? {
            in: Routes[route].transition instanceof Object ?
                Routes[route].transition.in || undefined : Routes[route].transition,
            out: Routes[route].transition instanceof Object ?
                Routes[route].transition.out || undefined : undefined
        } : undefined
    ])
)

export const getRouteByPath = path => Hotspots.filter(route => route.to === path)[0] || undefined

export default () => <>{Object.keys(Routes).map((route, key) => {
    const props = Routes[route]

    return <Route key={key} exact={props.exact} path={route} component={props.component}/>
})}</>