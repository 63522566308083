import React, { useContext } from 'react'
import Resources from '../constants/Resources'
import { Hotspots, Backgrounds, Content } from '../Routes'
import { TransitionLink, TransitionPage, TransitionContext } from '../contexts/TransitionContext'

export default () => {
    const { transitioning } = useContext(TransitionContext)

    return <TransitionPage background={Backgrounds.Section1} contentBackground={Content.Section1}>
        <div className="hotspots" style={{
            display: transitioning ? 'none' : 'block'
        }}>
            {Hotspots.filter(hotspot => hotspot.to.substr(0, 10) === '/section1/').map((props, key) => 
                <div key={key} className="hotspot" style={{ ...props.styles, position: 'absolute' }}>
                    <div className="hotspot-container">
                        <TransitionLink {...props}>
                            <img src={Resources.HotspotImage} alt="hotspot" />
                        </TransitionLink>
                    </div>
                </div>)}
        </div>
    </TransitionPage>
}