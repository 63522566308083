export default {
    MenuBackgroundImage: '/static/images/menu/background.png',
    MenuContentImage: '',
    Section1BackgroundImage: '/static/images/section1/background.png',
    Section1ContentImage: '',
    Section1TransitionIn: process.env.REACT_APP_STATIC ? '' : '/static/videos/section1/in.mp4',
    Section1TransitionOut: process.env.REACT_APP_STATIC ? '' : '/static/videos/section1/out.mp4',
    Section1SwitchTransition: process.env.REACT_APP_STATIC ? '' : '/static/videos/section1/switch.mp4',
    Section2BackgroundImage: '/static/images/section2/background.png',
    Section2ContentImage: '',
    Section2TransitionIn: process.env.REACT_APP_STATIC ? '' : '/static/videos/section2/in.mp4',
    Section2TransitionOut: process.env.REACT_APP_STATIC ? '' : '/static/videos/section2/out.mp4',
    Section2SwitchTransition: process.env.REACT_APP_STATIC ? '' : '/static/videos/section2/switch.mp4',
    Page1BackgroundImage: '/static/images/page1/background.png',
    Page1ContentImage: '',
    Page1TransitionIn: process.env.REACT_APP_STATIC ? '' : '/static/videos/page1/in.mp4',
    Page1TransitionOut: process.env.REACT_APP_STATIC ? '' : '/static/videos/page1/out.mp4',
    Page2BackgroundImage: '/static/images/page2/background.png',
    Page2ContentImage: '',
    Page2TransitionIn: process.env.REACT_APP_STATIC ? '' : '/static/videos/page2/in.mp4',
    Page2TransitionOut: process.env.REACT_APP_STATIC ? '' : '/static/videos/page2/out.mp4',
    Page3BackgroundImage: '/static/images/page3/background.png',
    Page3ContentImage: '',
    Page3TransitionIn: process.env.REACT_APP_STATIC ? '' : '/static/videos/page3/in.mp4',
    Page3TransitionOut: process.env.REACT_APP_STATIC ? '' : '/static/videos/page3/out.mp4',
    Page4BackgroundImage: '/static/images/page4/background.png',
    Page4ContentImage: '',
    Page4TransitionIn: process.env.REACT_APP_STATIC ? '' : '/static/videos/page4/in.mp4',
    Page4TransitionOut: process.env.REACT_APP_STATIC ? '' : '/static/videos/page4/out.mp4',
    Page5BackgroundImage: '/static/images/page5/background.png',
    Page5ContentImage: '',
    Page5TransitionIn: process.env.REACT_APP_STATIC ? '' : '/static/videos/page5/in.mp4',
    Page5TransitionOut: process.env.REACT_APP_STATIC ? '' : '/static/videos/page5/out.mp4',
    OverlayImage: '/static/images/overlay.png',
    HotspotImage: '/static/images/hotspot.png'
}