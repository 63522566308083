import React, { useCallback, useEffect, useRef, useState } from 'react'
import Ticker from 'react-ticker'
import FitText from 'react-fittext'

export default props => {
    const ref    = useRef()
    const items  = ['Event News', 'Next Symposia - 11:30', 'Poster Session Now In Progress', 'Watch Again Our Guest Speaker - Sonja Surname']
    const sep    = '\u00A0\u00A0+++\u00A0\u00A0\u00A0\u00A0\u00A0'
    const [fontSize, setFontSize] = useState()
    const [runOnce, setRunOnce] = useState(true)
    const updateRunOnce = useCallback(setRunOnce)
    const resize = () => {
        if (ref.current) {
            const scale  = parseFloat(ref.current.offsetHeight / 100 * 67, 10)
            const result = parseFloat(ref.current.offsetHeight - scale, 10)
            
            setFontSize(result)
        }
    }

    useEffect(() => {
        window.addEventListener('resize', resize)

        if (runOnce) {
            resize()
            updateRunOnce(false)
        }

        return () => {
            window.removeEventListener('resize', resize)
        }
    }, [runOnce, updateRunOnce])

    return <div ref={ref} className="ticker-container" style={{ fontSize }}>
        <Ticker>
            {() => <FitText compressor={8}><p>{items.join(sep)}{sep}</p></FitText>}
        </Ticker>
    </div>
}