import React, { createContext, useState, useContext, useEffect, useRef } from 'react'
import { BrowserRouter, Route, Switch, useHistory, useLocation, matchPath } from 'react-router-dom'
import { TransitionGroup, Transition, CSSTransition } from 'react-transition-group'

let globalLocation, timeout

const createNamedContext = (name, state = undefined) => {
    const context = createContext(state)

    context.displayName = name

    return context
}

const Provider = props => {
    const videoPlayer                         = useRef()
    const history                             = useHistory()
    const currentLocation                     = useLocation()
    const [video, setVideo]                   = useState()
    const [active, setActive]                 = useState(!!!globalLocation)
    const [firstRun, disableFirstRun]         = useState(true)
    const [transitioning, setTransition]      = useState(!!globalLocation)
    const [transitions, applyTransitions]     = useState(Object.fromEntries(
        Object.entries(props.routes).map(([ pathname, config ]) => {
            const transition = config.transition || false

            return [
                pathname,
                transition
            ]
        })
    ))
    const [transition, setTransitions]        = useState(props.route(currentLocation) ?
        props.route(currentLocation).transition || undefined : undefined)
    const [preload]                           = useState(props.preload instanceof Object ?
        Object.keys(props.preload).map(key => props.preload[key]) : (
            props.preload instanceof Array ? props.preload : undefined
        )
    )
    const currentTransition = transition
    const previousLocation  = currentLocation.pathname.substring(0,
        currentLocation.pathname.lastIndexOf('/')) || '/'
    const updateTransitions = (type = 'add', transition = null) => {
        const list = [].concat(transitions)

        if (type.toLowerCase() === 'remove') {
            list.pop()
        } else {
            list.push(transition)
        }

        applyTransitions(list)
    }

    const navigate = options => {
        const curLoc = currentLocation
        const video  = videoPlayer.current ? videoPlayer.current.querySelector('video') : null

        let { to: path, transition, state: selectedState } = options || {}
        let resetNavigation = () => {
            setTransition(false)
            setVideo(undefined)
            if (video) {
                video.removeEventListener('ended', resetNavigation)
            }
            if (!currentTransition) {
                setTransitions(transitions[path] || false)
            }
        }
                        
        if (options && currentLocation.pathname !== path) {
            clearTimeout(timeout)
            
            setTransition(true)

            const delay = 0

            timeout = setTimeout(() => {
                if ('switch' in options && options.switch === true) {
                    let trans = transitions[curLoc.pathname] || false
                    
                    transition = trans && trans.switch ? (trans.switch || false) : false

                    setTransitions(transition)
                } else {
                    let state = selectedState === 'out' ? 'out' : 'in',
                        trans = transitions[state === 'in' ? path : curLoc.pathname] || false
                    
                    transition = trans ? (trans[state] || false) : false

                    setTransitions(transition)
                }

                if (transition) {
                    setVideo(transition)

                    if (video) { 
                        video.removeEventListener('ended', resetNavigation)
                        video.addEventListener('ended', resetNavigation)
                        
                        video.play().then(() => {
                            history.push(path)
                        }).catch(() => {
                            video.play().then(() => {
                                history.push(path)
                            }).catch(() => {
                                history.push(path)
                                resetNavigation()
                            })
                        })
                    } else {
                        history.push(path)
                        resetNavigation()
                    }
                } else {
                    history.push(path)
                    resetNavigation()
                }
            }, delay)
        }
    }

    useEffect(() => {
        disableFirstRun(false)
    }, [])
    
    return <Route render={({ location }) => {
        globalLocation = location

        return <TransitionContext.Provider value={{
            active,
            setActive,
            location,
            navigate,
            firstRun,
            transitioning,
            video,
            setVideo,
            videoPlayer,
            setTransition,
            transition,
            previousLocation,
            updateTransitions
        }}>
            <div className="app">
                {props.children}
            </div>
            {preload ? 
                <div style={{ display: 'none', opacity: 0, width: 0, height: 0 }}>
                    {preload.map((image, key) => <img key={key} src={image} alt="" />)}
                </div>
            : null}
        </TransitionContext.Provider>
    }} />
}

export const TransitionContext = createNamedContext('TransitionContext')

export const TransitionVideoPlayer = () => {
    const { videoPlayer, video, transitioning, setTransition, setVideo } = useContext(TransitionContext)

    return <div ref={videoPlayer} className={`transition-container${transitioning ? ' transition-container-show' : ''}`}>
        <video src={video} style={{
            width: '100%',
            height: '100%'
        }} playsInline onEnded={() => {
            setTransition(false)
            setVideo(undefined)
            videoPlayer.current.querySelector('video').load()
        }} />
    </div>
}

export const TransitionRouter = props => {
    const { location } = useContext(TransitionContext)
    const { key }      = location

    return <div className="container">
        <TransitionGroup component={null}>
            <Transition key={key} appear={true} timeout={100}>
                <Switch location={location}>
                    {props.children}
                </Switch>
            </Transition>
        </TransitionGroup>
    </div>
}

export const TransitionLink = props => {
    const { transitioning, navigate } = useContext(TransitionContext)

    return <a href={props.to}
        onClick={e => {
            e.preventDefault()

            if (transitioning) {
                return false
            }

            navigate(props)

            if ('onClick' in props && props.onClick instanceof Function) {
                props.onClick.apply(null, [e])
            }
        }}>{props.children}
    </a>
}

export const TransitionPage = props => <div className="page" style={{
    ...props.style,
    ...(props.background ? {
        backgroundImage: `url(${props.background})`,
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat'
    } : {})
}}>{
    props.contentBackground ? <TransitionContent background={props.contentBackground}>
        {props.children}
    </TransitionContent> : props.children
}</div>

export const TransitionContent = props => {
    const { transitioning, setActive } = useContext(TransitionContext)

    return <CSSTransition
        in={!transitioning}
        timeout={1000}
        classNames="fade"
        onEntered={() => setActive(true)}
        onExit={() => setActive(false)}
    >
        <div className="content" style={{
            ...props.style,
            ...(props.background ? {
                backgroundImage: `url(${props.background})`,
                backgroundSize: '100%',
                backgroundRepeat: 'no-repeat'
            } : {})
        }}>{props.children}</div>
    </CSSTransition>
}

export default props => {
    const route = location => props.routes[Object.keys(props.routes).filter(route => matchPath(
        location.pathname, { ...props.routes[route], path: route || false }))[0]] || undefined
    
    return <BrowserRouter>
        <Provider {...props} route={route} />
    </BrowserRouter>
}