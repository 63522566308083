import React from 'react'
import Routes, { Backgrounds, RouteObject } from './Routes'
import TransitionContextProvider, {
    TransitionContext,
    TransitionRouter,
    TransitionVideoPlayer,
    TransitionLink
} from './contexts/TransitionContext'
import Ticker from './components/Ticker'

const Navigation = props => {
    return <TransitionContext.Consumer>{({ transition, transitioning, location, previousLocation }) => {
        return <div className="overlay">
            {
                location.pathname !== '/' ?
                    <TransitionLink to={previousLocation || '/'} transition={transition} state="out">
                        <div className="hotspot" style={{ width: '5%',  top: '2%', left: '1%', zIndex: 999999, position: 'absolute' }}>
                            <div className="hotspot-container">
                                <div className="back">
                                    <img src="/static/images/back.png" alt="Back" />
                                </div>
                            </div>
                        </div>
                    </TransitionLink> : null
            }
            {
                !transitioning && location.pathname.substr(0, 8) === '/section' && location.pathname.split('/').filter(a => a !== '').length === 1 ? (
                    location.pathname.substr(0, 9) === '/section2' ?
                        <TransitionLink to="/section1" transition={transition} state="out" switch={true}>
                            <div className="hotspot" style={{ width: '5%', top: '48%', left: '0.3%', zIndex: 999999, position: 'absolute' }}>
                                <div className="hotspot-container">
                                    <img src="/static/images/arrow.png" alt="swap" style={{
                                        width: '50%',
                                        height: '100%',
                                        transform: 'scaleX(-1)',
                                        pointerEvents: 'all'
                                    }} />
                                </div>
                            </div>
                        </TransitionLink> :
                        <TransitionLink to="/section2" transition={transition} state="out" switch={true}>
                            <div className="hotspot" style={{ width: '5%', top: '48%', right: '0.3%', zIndex: 999999, position: 'absolute' }}>
                                <div className="hotspot-container">
                                    <img src="/static/images/arrow.png" alt="swap" style={{
                                        width: '50%',
                                        height: '100%',
                                        pointerEvents: 'all'
                                    }} />
                                </div>
                            </div>
                        </TransitionLink>
                ) : null
            }
        </div>
    }}</TransitionContext.Consumer>
}

export default () => {
    return <div className="aspect-ratio ar169" style={{ flex: 1 }}>
        <div className="content">
            <TransitionContextProvider preload={Backgrounds} routes={RouteObject}>
                <Navigation />
                <TransitionRouter>
                    <Routes />
                </TransitionRouter>
                <TransitionVideoPlayer />
                <Ticker />
            </TransitionContextProvider>
        </div>
    </div>
}
